import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { mainModule } from 'process';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'restaurants',
    loadChildren: () => import('./restaurants/restaurants.module').then( m => m.RestaurantsPageModule)
  },
  {
    path: 'restaurantmenu',
    loadChildren: () => import('./restaurantmenu/restaurantmenu.module').then( m => m.RestaurantmenuPageModule)
  },
  {
    path: 'restaurantedit',
    loadChildren: () => import('./restaurantedit/restaurantedit.module').then( m => m.RestauranteditPageModule)
  },
  {
    path: 'restaurantmenu/:id',
    loadChildren: () => import('./restaurantmenu/restaurantmenu.module').then( m => m.RestaurantmenuPageModule)
  },
  {
    path: 'restaurantedit/:id',
    loadChildren: () => import('./restaurantedit/restaurantedit.module').then( m => m.RestauranteditPageModule)
  },
  {
    path: 'rubroslista',
    loadChildren: () => import('./rubroslista/rubroslista.module').then( m => m.RubroslistaPageModule)
  },
  {
    path: 'platoedit',
    loadChildren: () => import('./platoedit/platoedit.module').then( m => m.PlatoeditPageModule)
  },
  {
    path: 'menuitems',
    loadChildren: () => import('./menuitems/menuitems.module').then( m => m.MenuitemsPageModule)
  },
  {
    path: 'menu-item-edit',
    loadChildren: () => import('./menu-item-edit/menu-item-edit.module').then( m => m.MenuItemEditPageModule)
  },
  {
    path: 'rubro-edit',
    loadChildren: () => import('./rubro-edit/rubro-edit.module').then( m => m.RubroEditPageModule)
  },
  {
    path: 'menuitem-edit',
    loadChildren: () => import('./menuitem-edit/menuitem-edit.module').then( m => m.MenuitemEditPageModule)
  },
  {
    path: 'menuitem-alergias',
    loadChildren: () => import('./menuitem-alergias/menuitem-alergias.module').then( m => m.MenuitemAlergiasPageModule)
  },
  {
    path: 'modificablegrupo-edit',
    loadChildren: () => import('./modificablegrupo-edit/modificablegrupo-edit.module').then( m => m.ModificablegrupoEditPageModule)
  },
  {
    path: 'modificable-edit',
    loadChildren: () => import('./modificable-edit/modificable-edit.module').then( m => m.ModificableEditPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'qr/:id',
    loadChildren: () => import('./qr/qr.module').then( m => m.QrPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
