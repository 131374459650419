//AuthInterceptor.ts

//...some imports...
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
   constructor() {}

   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
       // Get the auth header from your auth service.
       if (req.url.includes("mejorsalimos.com")||req.url.includes("localhost")) {
            const authReq = req.clone({headers: req.headers.set('Authorization', "Basic " + btoa("admin:1234"))});
            return next.handle(authReq);
       } else {
            return next.handle(req.clone());
       }      
   }
}